import React from 'react';

import {
  FaFacebookF,
  FaGithub,
  FaLinkedin,
  FaTwitter,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const FooterThree = ({parentClass}) => {
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><Link to="https://facebook.com/"><FaFacebookF /></Link></li>
                            <li><Link to="https://twitter.com/"><FaTwitter /></Link></li>
                            <li><Link to="https://github.com/Itrustss/"><FaGithub /></Link></li>
                            <li><Link to="https://www.linkedin.com/"><FaLinkedin /></Link></li>

                        </ul>
                    </div>
                </div>
                
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved.</span>
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterThree;