import React from 'react';

import PropTypes from 'prop-types';

const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>iTrust Software Services | {title}</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="At iTrust Software Services, we develop Secure, mobile-friendly websites and web portals that have self-explanatory navigation, mobile-specific interactive elements, and smooth network performance, leading to a high conversion rate." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;